<template>
  <div>
    <v-main>
      <v-img
        :class="{
          'v-image--sm': this.$vuetify.breakpoint.smAndDown,
          'v-image--md': this.$vuetify.breakpoint.mdAndDown
        }"
        src="@/assets/MMS-Login-Page.jpg"
        min-height="100%"
      >
        <!-- Gradient from background image: gradient="to right, #3F5FD0DF, #3F0047BA, #3FA25EB5" -->
        <v-container
          id="router-container"
          :style="{ minHeight: containerMinHeight + 'px'}"
        >
          <CoreBanner />
          <CoreSnackbar />
          <router-view />
        </v-container>
      </v-img>
      <CoreFooter class-color="secondary" />
    </v-main>
  </div>
</template>

<script>

export default {
  name: 'ExternalView',

  components: {
    CoreFooter: () => import('../core/CoreFooter'),
    CoreBanner: () => import('../core/CoreBanner'),
    CoreSnackbar: () => import('../core/CoreSnackbar')
  },

  data: function () {
    return {
      windowHeight: 0,
      containerMinHeight: 0,
      footerSelector: document.getElementById('outside-footer')
    }
  },

  watch: {
    windowHeight: {
      handler (newValue, oldValue) {
        this.containerMinHeight =
          (newValue || oldValue) - this.getFooterHeight()
      }
    }
  },

  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowHeight)
      // Init
      this.getWindowHeight()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowHeight)
  },

  methods: {
    getWindowHeight (event) {
      this.windowHeight = document.documentElement.clientHeight
    },
    getFooterHeight () {
      return this.footerSelector
        ? this.footerSelector.style.height.toString().replace('px', '')
        : 76
    }
  }
}
</script>
