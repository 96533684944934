var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-main",
        [
          _c(
            "v-img",
            {
              class: {
                "v-image--sm": this.$vuetify.breakpoint.smAndDown,
                "v-image--md": this.$vuetify.breakpoint.mdAndDown
              },
              attrs: {
                src: require("@/assets/MMS-Login-Page.jpg"),
                "min-height": "100%"
              }
            },
            [
              _c(
                "v-container",
                {
                  style: { minHeight: _vm.containerMinHeight + "px" },
                  attrs: { id: "router-container" }
                },
                [_c("CoreBanner"), _c("CoreSnackbar"), _c("router-view")],
                1
              )
            ],
            1
          ),
          _c("CoreFooter", { attrs: { "class-color": "secondary" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }